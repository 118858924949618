@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=ed7df8d5-df47-40c6-a733-ba03167981ce&fontids=5608471");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body{
        @apply font-body
    }
    @font-face{
        font-family:"Arial MT W01 Regular";
        src:url("Fonts/5608471/1c9448fe-7605-4269-9ad3-d8c2dbe01ebc.woff2") format("woff2"),url("Fonts/5608471/413af1a3-8b25-474c-9bb6-8d89bb907596.woff") format("woff");
    }
}

html {
    height: 100%;
    scroll-behavior: smooth;
}

img{
    max-width: unset;
}

